<template>
	<BaseBadge
			:badge-class="statusProcessed.bgClass"
			:icon-left="statusProcessed.icon"
			:text="statusProcessed.text"
	/>
</template>

<script lang="ts" setup>
	import {computed, type PropType} from 'vue';
	import type {ActionDecorator} from '~/lib/types/actionTypes';
	import {getProcessedActionStatus} from '~/lib/actionHelper';
	import BaseBadge from '~/components/generic/badge/BaseBadge.vue';

	const props = defineProps({
		action: {
			type: Object as PropType<Pick<ActionDecorator, 'status' | 'endDate'>>,
			required: true
		}
	});

	const statusProcessed = computed(() => getProcessedActionStatus(props.action));
</script>
